export const BG_COVER = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/cover.jpg?updatedAt=1706686519444`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/welcome.jpg?updatedAt=1706686519498`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/filternew.jpg?updatedAt=1707047790728`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/dekstop.jpg?updatedAt=1706686522538`;

export const IMG_AYAT = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/footer.jpg?updatedAt=1706686519182`;
export const IMG_LOGO = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/logo.png?updatedAt=1706687728156`;

export const IMG_MAN = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/groom.jpg?updatedAt=1706686519399`;
export const IMG_GIRL = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/bride.jpg?updatedAt=1706686519417`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/g1.jpg?updatedAt=1706686519480`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/g2.jpg?updatedAt=1706686519392`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/bxwgcojbe/aditya-magdalena/g3.jpg?updatedAt=1706686519400`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;